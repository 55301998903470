import React from 'react';
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout';
import NotFoundPage from '../pages/404';
import { WorldsFairHead } from '../components/Head';

const Page = ({ data: { page } }: PageProps<Queries.PageTemplateQuery>) => {
    if (!page) {
        return <NotFoundPage />;
    }

    const {
        title,
        content,
    } = page;

    return (
        <Layout location="page" showSignup={true} seoData={page.seo}>
            <h1>{title}</h1>
            {content && <article dangerouslySetInnerHTML={{ __html: content }} />}
        </Layout>
    );
}

export default Page;

export const query = graphql`
    query PageTemplate ($id: Int!) {
        page: wpPage(databaseId: { eq: $id }) {
            title
            content
            ...SeoData
        }
    }
`

export const Head = () => <WorldsFairHead />